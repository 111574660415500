import React from 'react'
import { isArray } from 'lodash'
import { format, isEqual } from 'date-fns'
import { Tag } from './tag'
import { CvEntry, DateFormat } from './types';
import { EmojiReplacer } from './emojiReplacer';

type Props = {
    data: CvEntry;
    previousEntry?: CvEntry;
}

const setHTMLContent = (text: string) => ({
    __html: EmojiReplacer(text)
})

const getFormat = (dateFormat: DateFormat = 'monthYear'): string => {
    if (dateFormat === 'year') return 'yyyy'
    return 'MMM yyyy'
}

export const Entry: React.FC<Props> = ({ data }: Props) => (
    <div className={`entry ${!data.org ? 'continuation' : 'page-break'}`}>
        {data.org &&
            <div className={`org ${data.content.length === 0 ? 'no-content' : ''}`}>
                <aside className={`info ${data.content.length === 0 ? 'wide' : ''}`}>
                    <h2>{data.org}</h2>
                </aside>
                <div>
                    {data.orgDescription || ''}
                </div>
            </div>
        }
        <div className="position">
            <aside className={`info ${data.content.length === 0 ? 'wide' : ''}`}>
                {data.jobTitle && <h3>{data.jobTitle}</h3>}
                {data.startDate && (
                    <div className="dates">
                        <span className="date">{format(data.startDate, getFormat(data.dateFormat))}</span>
                        {(!data.endDate || !isEqual(data.endDate, data.startDate)) && <> - <span className="date">{data.endDate ? format(data.endDate, getFormat(data.dateFormat)) : 'Present'}</span></>}
                    </div>
                )}
                {data.school && <div className="school" dangerouslySetInnerHTML={setHTMLContent(data.school)} />}
                {data.type && isArray(data.type) && data.type.map((t, index) => <div className="type" key={index} dangerouslySetInnerHTML={setHTMLContent(t)} />)}
                {data.type && !isArray(data.type) && <div className="type" dangerouslySetInnerHTML={setHTMLContent(data.type)} />}
                {data.info && <div className="location">{data.info}</div>}
            </aside>
            {data.content.length > 0 && <div className="details">
                {data.content.map((p, index) => <div className="p" dangerouslySetInnerHTML={setHTMLContent(p)} key={index} />)}
                {data.tags && data.tags.length > 0 &&
                <div className="tags">
                    {data.tags.map((tag, index) => <Tag label={tag} key={index} />)}
                </div>
                }
            </div>}
        </div>
    </div>
)