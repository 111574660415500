import React from 'react'
import { CvColumnSection, CvListSection, CvRowSection, CvSection } from './types';
import { ColumnSection } from './columnSection';
import { Section } from './section';
import { ListSection } from './listSection';
import { RowSection } from "./rowSection";

type Props = {
    section: CvSection | CvListSection | CvColumnSection | CvRowSection;
}

export const SectionSwitch:React.FC<Props> = ({ section }: Props) => {
    if (section.type === 'list') {
        return <ListSection data={section} />
    } else if (section.type === 'column') {
        return <ColumnSection data={section} />
    } else if (section.type === 'row') {
        return <RowSection data={section} />
    }
    return <Section data={section} />
}