import React from 'react'
import { CvRowSection } from './types';
import { SectionSwitch } from './sectionSwitch';

type Props = {
  data: CvRowSection;
}

export const RowSection:React.FC<Props> = ({ data }: Props) => (
  <section className="rows">
      {data.rows.map((row, index) => <div key={index} className="row"><SectionSwitch section={row} /></div>)}
  </section>
)