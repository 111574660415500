import React, { ReactElement, useLayoutEffect, useState } from 'react'

import { Layout } from '../components/layout'
import { CvViewer } from '../components/cvViewer'
import { graphql, useStaticQuery } from 'gatsby';

const CvPage = () => {
    const data = useStaticQuery(graphql`
        query {
          site {
            siteMetadata {
              title
              tagline
              linkedInProfile
              phone
              email
              website
              location
            }
          }
        }
    `)

    const [cv, setCv] = useState<ReactElement>()
    useLayoutEffect(() => {
        setCv(<CvViewer
            title={data.site.siteMetadata?.title}
            tagline={data.site.siteMetadata?.tagline}
            location={data.site.siteMetadata?.location}
            phone={data.site.siteMetadata?.phone}
            email={data.site.siteMetadata?.email}
            website={data.site.siteMetadata?.website}
            linkedInProfile={data.site.siteMetadata?.linkedInProfile}
        />)
    }, [])

    return (
        <Layout>
            {cv}
            <></>
        </Layout>
    )
}

export default CvPage
