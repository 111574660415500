import React from 'react'
import { Entry } from './entry'
import { CvSection } from './types';

type Props = {
  data: CvSection;
}

export const Section:React.FC<Props> = ({ data }: Props) => (
  <section className="section">
    <h1>{data.title}</h1>
    {data.entries.map((entry, index) => <Entry key={index} data={entry} previousEntry={index > 0 ? data.entries[index - 1] : undefined} />)}
  </section>
)