import React from 'react'
import { CvColumnSection } from './types';
import { SectionSwitch } from './sectionSwitch';

type Props = {
  data: CvColumnSection;
}

export const ColumnSection:React.FC<Props> = ({ data }: Props) => (
  <section className="columns">
      {data.columns.map((col, index) => <div key={index} className="column"><SectionSwitch section={col} /></div>)}
  </section>
)