import React from 'react'
import { cvData } from './cvData'

import './cv.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faMobileAlt, faGlobe, faMapMarkerAlt, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { SectionSwitch } from './sectionSwitch';
// @ts-ignore
import cvFile from '../files/Leanne_Walsham_CV.pdf';
import { EmojiReplacer } from './emojiReplacer';

type Props = {
    title: string;
    tagline: string;
    location?: string;
    email?: string;
    phone?: string;
    website?: string;
    linkedInProfile?: string;
}

export const CvViewer: React.FC<Props> = ({ title, tagline, phone, email, website, location, linkedInProfile }: Props) => (
    <div className="cv">
        <div className="download"><a href={cvFile}><FontAwesomeIcon icon={faFilePdf} /></a> <a href={cvFile}>Download PDF</a></div>
        <header>
            <h1>{title}</h1>
            <h2 dangerouslySetInnerHTML={{ __html: EmojiReplacer(tagline).split('|').join('&nbsp;|&nbsp;') }} />
            <aside>
                {email && <span><a href={`mailto:${email}`}><FontAwesomeIcon icon={faEnvelope}/></a> <a
                    href={`mailto:${email}`}>{email}</a></span>}
                {phone && <span><a href={`tel:${phone}`}><FontAwesomeIcon icon={faMobileAlt}/></a> <a
                    href={`tel:${phone}`}>{phone}</a></span>}
                {website && <span><a href={`https://${website}`}><FontAwesomeIcon icon={faGlobe}/></a> <a
                    href={`https://${website}`}>{website}</a></span>}
                {linkedInProfile && <span><a href={`https://www.linkedin.com/in/${linkedInProfile}`}><FontAwesomeIcon
                    icon={faLinkedin}/></a> <a
                    href={`https://www.linkedin.com/in/${linkedInProfile}`}>/{linkedInProfile}</a></span>}
                {location && <span><FontAwesomeIcon icon={faMapMarkerAlt}/> {location}</span>}
            </aside>
        </header>
        {cvData.summary && <section className="summary">
            <h1>About Me</h1>
            {cvData.summary.map((p, index) => <p key={index} dangerouslySetInnerHTML={{ __html: EmojiReplacer(p) }} />)}
        </section>}
        {cvData.sections.map((section, index) => <SectionSwitch key={index} section={section}/>)}
    </div>
)