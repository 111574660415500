import React from 'react'
import { Entry } from './entry'
import { CvColumnSection, CvListSection, CvSection } from './types';
import { Section } from './section';

type Props = {
  data: CvListSection;
}

export const ListSection:React.FC<Props> = ({ data }: Props) => (
  <section className="list">
    <h1>{data.title}</h1>
    <ul>{data.items.map((item, index) => <li key={index}>{item}</li>)}</ul>
  </section>
)